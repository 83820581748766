import { lerp, isMobileDevice } from "./helpers";

const mouseCursor = document.getElementById("mouse_cursor");
const videoIcon = document.querySelector('.video-cursor');
const audioIcon= document.querySelector('.audio-cursor');
const polygon = document.getElementById('polygon')
const videoPrompt = document.getElementById('video-prompt')
const duration = document.getElementById('video-duration')


const CURSOR_SPEED = 0.05 // Value between 0 and 1

let cursorX = 0,
  cursorY = 0,
  currentX = 0,
  currentY = 0;

const moveCursor = e => {
    mouseCursor.style.display = isMobileDevice() ? 'none' : "inline-block";
    cursorX = e.clientX;
    cursorY = e.clientY;
}

const showVideoIcon = e => {

  if(e.target instanceof HTMLVideoElement) {
    videoIcon.style.opacity = 1
    polygon.style.opacity = 1
    mouseCursor.style.border = 'none'
    videoPrompt.style.opacity = 1
    duration.innerHTML = '00:' + e.target.duration.toString().replace('.', ':')
  } else {
    videoIcon.style.opacity = 0
    polygon.style.opacity = 0
    mouseCursor.style.border = '#fff 1.5px solid'
    videoPrompt.style.opacity = 0
  }

  if (e.target instanceof HTMLAnchorElement) {
    mouseCursor.style.height = '20px'
    mouseCursor.style.width = '20px'
  } else {
    mouseCursor.style.height = '130px'
    mouseCursor.style.width = '130px'
  }
}

window.addEventListener("mousemove", moveCursor);
window.addEventListener('mousemove', showVideoIcon)


export function animateCursors() {
  currentX = lerp(currentX, cursorX, CURSOR_SPEED);
  currentY = lerp(currentY, cursorY, CURSOR_SPEED);
  mouseCursor.style.transform = `translate(calc(-50% + ${currentX}px), calc(-50% + ${currentY}px)) `;
  requestAnimationFrame(animateCursors);
}

if (isMobileDevice()) {
    window.removeEventListener("mouseover", mouseCursor, true)
    window.removeEventListener("mousemove", showVideoIcon, true)
} else {
  animateCursors();
}


export function setCursorStyle(val) {
  const html = document.querySelector('html');
  html.style.cursor = val
}