import gsap, {Expo} from "gsap"

export function workListAnimation() {
    const projects = document.querySelectorAll('.project')
    const list = document.querySelectorAll('.project-list li')
    const imgsContainer = document.querySelector('.imgs-wrap')
    const images = Array.from(document.querySelectorAll('.bg-image'))
    const projectPreviews = document.querySelectorAll('.project-preview')

    // Put default image on top of the others
    images.forEach(el => {

        if (el.id === 'default') {
            gsap.to(el, {opacity: 1, duration: .2})
            
        } else {
            
            gsap.to(el, {opacity: 0, duration: .2})
        }
    })

    const mouseLeaveFunction = (elem) => {
        // Hide hover relaated image
        gsap.to(images.find(el => el.id === elem.dataset.projectName), {opacity: 0, duration: .2})
        imgsContainer.classList.remove('force-show')
        gsap.to(images.find(el => el.id === 'default'), {opacity: 1, duration: .2})

        gsap.to('.description', {opacity: 1, duration: .3})
        gsap.to('.selected', {opacity: 1, duration: .3})
        gsap.to('footer', {opacity: 1, duration: .3})
        animateScrollItem('return-animation')
        animateScrollItem('hide')

        projectPreviews.forEach(e => {
            const elemPreview = e.childNodes[0]
            gsap.to(elemPreview.childNodes[1], {marginTop: 'calc(100vh + 100px)', ease: Expo.easeInOut, opacity: 1})
            gsap.to(elemPreview.childNodes[0], {
                transform: 'translateX(-50%)',
                ease: Expo.easeInOut,
                opacity: 1
            })
        })

        // Show all items
        list.forEach(listItem => gsap.to(listItem, {opacity: 1, duration: .2}))
    }

    const mouseEnterFunction = (elem) => {
        // Show hover related image
        gsap.to(images.find(el => el.id === 'default'), {opacity: 0, duration: .2})
        imgsContainer.classList.add('force-show')
        gsap.to(images.find(el => el.id === elem.dataset.projectName), {opacity: 1, duration: .2})

        // Hide other projects
        list.forEach(listItem => {
            if (listItem.childNodes[0].dataset.projectName === elem.dataset.projectName) {
                gsap.to(listItem, {opacity: 1, duration: .2})
            } else {
                gsap.to(listItem, {opacity: 0, duration: .2})
            }
        })

        gsap.to('.description', {opacity: 0, duration: .3})
        gsap.to('.selected', {opacity: 0, duration: .3})
        gsap.to('footer', {opacity: 0, duration: .3})
        animateScrollItem('show')
        animateScrollItem('animate')

        projectPreviews.forEach(preview => {
            if (preview.dataset.projectName === elem.dataset.projectName) {
                const elemPreview = preview.childNodes[0]
                gsap
                    .timeline()
                    .to(elemPreview.childNodes[1], {marginTop: 'calc(100vh - 170px)', ease: Expo.easeInOut, opacity: 1})
                    .to(elemPreview.childNodes[0], {
                        transform: 'translate(-50%, -30%)',
                        ease: Expo.easeInOut,
                        opacity: 1
                    })
            } 
        })
    }
    
    projects.forEach(element => {

        element.addEventListener('mouseenter', () => mouseEnterFunction(element))

        element.addEventListener('mouseleave', () => mouseLeaveFunction(element), true)
    })
}

export const animateScrollItem = (animation) => {
    
    switch (animation) {
        case 'animate':
            gsap.to('#scroll-item svg', {transform: 'rotateZ(-135deg)'})
            gsap.to('.scroll-text-wrap div', {marginTop: -25})
            break;
        case 'return-animation':
            gsap.to('#scroll-item svg', {transform: 'rotateZ(0deg)'})
            gsap.to('.scroll-text-wrap div', {marginTop: 0})
            break;
        case 'show': 
            gsap.to('#scroll-item', {opacity: 1})
            break;
        case 'hide': 
        gsap.to('#scroll-item', {opacity: 0})
            break;
        default:
            console.error(`"${animation}" is not a valid scrollItem animation.`)
            break;
    }
}

export const removeCurrentProjectFromList = () => {
    const selected = document.getElementById('current-project');
    const list = document.querySelectorAll('.project-list li');

    list.forEach((el, i) => {
        if (selected?.dataset?.projectName && el?.childNodes?.[0].dataset.projectName === selected.dataset.projectName) {
            if (!i) {
                list[i + 1].style.display = 'none'
            } else {
                list[i - 1].style.display = 'none'
            }
            el.style.display = 'none'
        }
    })
}