export default function selectFooterElement() {
    const country = document.getElementById('current-country')
    const footer = document.querySelectorAll('footer h3')

    if (country) {
        footer.forEach(el => {
            if (el.innerHTML === country.innerHTML) {
                el.classList.add('footer-selected')
                return
            }
            el.classList.remove('footer-selected')
        })
    } 
}



