import Lenis from '@studio-freight/lenis';

export var Scroll = (function(){
	var instance;
	return {
		getInstance: function() {
			if (!instance) {
				instance = new Lenis({
          duration: 1.2,
          easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
          direction: 'vertical',
          smooth: true,
          smoothTouch: false,
          touchMultiplier: 2,
        })
			}
			return instance;
		}
	};
})();

export function forceScrollTop() {
  window.addEventListener('beforeunload', () => {
    window.scrollTo(0, 0);
  })
}

export const fadeOutBackgroundOnScroll = ({ progress }) => {
  if (!['/'].includes(window.location.pathname)) {

    const images = document.querySelector('.imgs-wrap')
    const text = document.querySelector('.fade-in-text');

    images.style.opacity = 1 - (progress * 1.5)
    if (text) {
      text.style.opacity = progress * 12
    }
  }
}
