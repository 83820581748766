import { Scroll, forceScrollTop } from './scroll'
import { animateCursors, setCursorStyle } from './cursor'
import selectFooterElement from './footer'
import Rellax from 'rellax'
import '../styles/index.scss'
import { runAfterLoading } from './helpers'
import { initialLoadingAnimation } from './loading'

// Wait for page load to run scripts and show the website
window.addEventListener('load', () => {
  const body = document.querySelector('body')
  setCursorStyle('wait')
  const scroll = Scroll.getInstance()

  initialLoadingAnimation()
  animateCursors()
  forceScrollTop()
  selectFooterElement()

  body.style.display = 'block'
  
  runAfterLoading(async () => {
    const  { workListAnimation, removeCurrentProjectFromList } = await import('./project-list')

    const { pageTransition } = await import('./page-transition')

    pageTransition()
    document.querySelectorAll('.bg-image').forEach(img => {
      img.style.transition = '.3s'
    })
    setCursorStyle('default')
    new Rellax('.parallax')
    workListAnimation()
    removeCurrentProjectFromList()
    
    function raf(time) {
      scroll.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
  })
})