import gsap, { Expo } from 'gsap'
import { animateScrollItem } from './project-list'
import { fadeOutBackgroundOnScroll, Scroll } from './scroll'
import { runAfterLoading } from './helpers'

export function initialLoadingAnimation() {

    const scroll = Scroll.getInstance()
    
    // Image reveal
    gsap.to(
        ".imgs-container > .bg-image",
        {
            y: "-100%",
            ease: Expo.easeInOut,
            duration: 3.5,
            delay: 0,
            stagger: 0.5
        }
    );

    gsap.to(".imgs-container", {
        duration: 2,
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateY(0%)',
        height: 2000,
        ease: Expo.easeInOut,
        delay: 5.5,
    });

    gsap
        // Show navabr anumation
        .timeline()
        .to('.main-nav', { duration: .5, ease: Expo.easeInOut, delay: 6, marginTop: 0 })
        .to('.side-nav', { duration: .5, ease: Expo.easeInOut, delay: .2, marginTop: 0 })

    gsap
        // Change city name
        .timeline()
        .to('.locations div', { y: -165, duration: 1, delay: 0.5, ease: Expo.easeInOut })
        .to('.locations div', { y: -165 * 2, duration: .8, delay: .3, ease: Expo.easeInOut })
        .to('.locations div', { y: -165 * 3, duration: 1.2, delay: .1, ease: Expo.easeInOut })
        .to('.locations div', { y: -165 * 4, duration: 1, delay: .2, ease: Expo.easeInOut })
        .to('.locations div', { y: -165 * 5, duration: 1, delay: 0.6, ease: Expo.easeInOut })

    gsap
        // Change percentage value
        .timeline()
        .to('.percentage-value div', { y: 165, duration: 1, delay: 1, ease: Expo.easeInOut })
        .to('.percentage-value div', { y: 165 * 2, duration: .8, delay: .3, ease: Expo.easeInOut })
        .to('.percentage-value div', { y: 165 * 3, duration: 1.2, delay: .1, ease: Expo.easeInOut })
        .to('.percentage-value div', { y: 165 * 4, duration: 1, delay: .2, ease: Expo.easeInOut })
        .to('.percentage-value div', { y: 165 * 5, duration: 1, delay: .2, ease: Expo.easeInOut })
        
    gsap
        .timeline()
        .to('.title', {y: 0, delay: 5.4, ease: Expo.easeInOut})
        .to('.coordinates', {paddingTop: 0, duration: 0.3,ease: Expo.easeInOut })
        .to('.central-item img', {top: 0, ease: Expo.easeInOut })
        .to('.bottom-info', {opacity: 1, ease: Expo.easeInOut, duration: .2})
        .to('.home-indented-text', {opacity: 1, ease: Expo.easeInOut, duration: 1})

    gsap.to('#scroll-item', {opacity: 1, ease: Expo.easeInOut, delay: 7})

    gsap
        .timeline()
        .to('.percentage h2', { y: 155, duration: .6, delay: 6.6, ease: Expo.easeInOut })

    runAfterLoading(() => {
        document.querySelector('body').style.overflow = 'scroll'
        scroll.start()

        let isShown = true
        scroll.on('scroll', ({ progress }) => {
            if (progress > 0.2) {
                if (isShown) {
                    animateScrollItem('hide')
                    isShown = false
                }
            } else {
                // Show element
                if (!isShown) {
                    animateScrollItem('show')
                    isShown = true
                }
            }
            fadeOutBackgroundOnScroll({ progress })
        })
    })
}